import $vuetify from 'vuetify/es5/locale/nl'

export default {
  generic: {
    ok: 'Ok',
    no: 'Nee',
    yes: 'Ja',
    save: 'Opslaan',
    saved: 'Opgeslagen',
    saving: 'Aan het opslaan',
    saveNow: 'Nu opslaan',
    loading: 'Bezig met laden',
    success: 'Gelukt',
    error: 'Fout opgetreden',
    errorMsg: 'Er is iets mis gegaan, probeer het later opnieuw...',
    cancel: 'Annuleren',
    search: 'Zoeken',
    searchNoResults: "Geen zoekresultaten gevonden voor '{search}'",
    update: 'Bijwerken',
    remove: 'Verwijderen',
    submit: 'Versturen',
    gotIt: 'Begrepen!',
    apply: 'Toepassen',
    menu: 'Menu',
    back: 'Terug',
    more: 'Meer',
    none: 'Geen',
    close: 'Sluiten',
    route: 'Route',
    routes: 'Routes',
    thisRoute: 'deze route',
    climbing: 'Klimmen',
    rope: 'Touw',
    boulder: 'Boulder',
    boulders: 'Boulders',
    thisBoulder: 'deze boulder',
    bouldering: 'Boulderen',
    settings: 'Instellingen',
    readMore: 'Lees meer',
    details: 'Details',
    today: 'Vandaag',
    tomorrow: 'Morgen',
    total: 'Totaal',
    copied: 'Gekopierd',
    comingSoon: 'Komt binnenkort',
    linkCopiedMsg: 'Link gekopierd naar plakbord',
    noData: 'Geen gegevens beschikbaar',
    refresh: 'Verversen',
    offlineMsg: 'Je bent offline.',
    offlineMsgSave: 'Wijzigingen worden opgeslagen wanneer u weer online bent.',
    offlineMsgNotSaved: 'Je wijzigingen worden niet opgeslagen.',
    badConnection: 'Slechte verbinding',
    pagination: {
      frst: 'Eerste pagina',
      prev: 'Vorige pagina',
      next: 'Volgende pagina',
      last: 'Laatste pagina',
    },
  },
  inputRules: {
    required: 'Vereist',
    minLength: 'Moet ten minste {length} tekens bevatten',
    maxLength: 'Mag maximaal {length} tekens bevatten',
    minVal: 'Moet minimaal {minVal} zijn',
    maxVal: 'Mag maximaal {maxVal} zijn',
    emailInvalid: 'Ongeldige e-mail',
    shouldMatchPwd: 'Moet overeenkomen met wachtwoord',
    dateInvalid: 'Ongeldige datum',
    timeInvalid: 'Ongeldige tijd',
  },
  sidenav: {
    profilePic: 'Profiel foto',
    withoutName: 'Gebruiker zonder naam',
    atGym: 'Bij {gymName}',
    profile: 'Profiel',
    myProfile: 'Mijn profiel',
    switchGym: 'Hal wisselen',
    selectGym: 'Hal selecteren',
    signOut: 'Uitloggen',
    signIn: 'Inloggen',
    closeApp: 'App sluiten',
    routes: 'Routes',
    boulders: 'Boulders',
    dashboard: 'Dashboard',
    competitions: 'Competities',
    myGym: 'Mijn hal',
    reservations: 'Reserveringen',
    inbox: 'Inbox',
    editRoutes: 'Route editor',
    editBoulders: 'Boulder editor',
    editCompetitions: 'Competitie editor',
    communications: 'Communicatie',
    statistics: 'Statistieken',
    gym: 'Hal',
    monitoring: 'Monitoring',
    help: 'Help',
    aboutUs: 'Over ons',
    contact: 'Contact',
    sendFeedback: 'Feedback geven',
    toc: 'Servicevoorwaarden',
  },
  auth: {
    loginTitle: 'Inloggen | TopLogger',
    loginDescription: 'Log in op de TopLogger web app of maak een account aan.',
    resetPwdTitle: 'Wachtwoord resetten | TopLogger',
    resetPwdDescription: 'Stel je wachtwoord voor TopLogger opnieuw in',
    unauthorized: 'Niet toegestaan',
    login: 'Inloggen',
    signIn: 'Inloggen',
    guestAccount: 'Gast account',
    resetPwd: 'Wachtwoord resetten',
    password: 'Wachtwoord',
    passwordConfirm: 'Wachtwoord herstellen',
    newsletters: 'Abonneren op de nieuwsbrief',
    forgotBtn: 'Wachtwoord vergeten?',
    forgotHeader: 'Ben je je wachtwoord vergeten?',
    forgotExpl:
      'Geen zorgen. Vul gewoon je email adres in, dan sturen we je een mailtje om je wachtwoord terug te zetten.',
    remember: 'Weet je hem weer?',
    signUp: 'Aanmelden',
    google: 'Aanmelden met Google',
    facebook: 'Aanmelden met Facebook',
    createAccount: 'Maak een account',
    createsAccount: 'Creëert automatisch een account gekoppeld aan je e-mail adres',
    haveAccount: 'Heb je al een account?',
    msgLoggedIn: 'Ingelogd',
    msgLoggedOut: 'Uitgelogd',
    msgSentConfirm: 'Een e-mail zal ter bevestiging naar u worden verzonden.',
    msgSentReset: 'Je ontvangt een e-mail om je wachtwoord opnieuw in te stellen.',
    msgPwdReset: 'Succes: je wachtwoord is bijgewerkt.',
    msgSentConfirmNew: 'Er wordt een mail verzonden om je nieuwe adres te bevestigen.',
    msgPwdUpdated: 'Je wachtwoord is bijgewerkt.',
    loginToSaveProgress: 'Je moet ingelogd zijn om je voortgang te bewaren',
    actionRequiresLogin: 'Voor deze actie moet je ingelogd zijn.',
  },
  profile: {
    title: 'Jouw profiel | TopLogger',
    description: 'Wijzig je profiel en en pas je instellingen voor TopLogger aan.',
    info: {
      changePicture: 'Wijzig profielfoto',
      zoomToCrop: 'Zoom en sleep om bij te snijden',
      upload: 'Upload',
      socialMedia: 'Social media',
      changeFile: 'Wijzig bestand',
      chooseFile: 'Kies bestand',
      firstName: 'Voornaam',
      lastName: 'Achternaam',
      male: 'Man',
      female: 'Vrouw',
      other: 'Anders',
      country: 'Land',
      city: 'Stad',
      dateBirth: 'Geboortedatum',
      hideDateBirth: 'Verberg datum voor andere gerbuikers',
      newsletters: 'Nieuwsbrief ontvangen',
      anonymous: 'Anoniem',
      length: 'Lengte (cm)',
      weight: 'Gewicht (kg)',
      email: 'Email',
      changeMail: 'Email wijzigen',
      enterNewMail: 'Voer je nieuwe email adres in',
      newEmail: 'Nieuwe email',
    },
    password: {
      changePwd: 'Wachtwoord wijzigen',
      newPwd: 'Nieuwe wachtwoord',
      confirmNewPwd: 'Wachtwoord bevestigen',
    },
    preferences: {
      preferences: 'Voorkeuren',
      language: 'Taal',
      anonymousSuffix: 'Niet zichtbaar in ranglijsten van de hal',
      genderSetToast: "Om in de ranglijst te verschijnen is je geslacht op 'Mannelijk' gezet.",
      anonymousSetToast: 'Je bent nu als anoniem ingesteld: ranglijsten vereisen een geslacht.',
    },
    notifications: {
      notifications: 'Notificaties',
      channels: 'Kanalen',
      push: 'Push',
      newRoutes: 'Nieuwe routes',
      newBoulders: 'Nieuwe boulders',
      news: 'Nieuws',
      competitions: 'Competities',
      restrictRange: 'Moeilijkheidsgraad beperken',
      onlyRoutesBetween: 'Alleen melden bij nieuwe routes tussen',
      onlybouldersBetween: 'Alleen melden bij nieuwe boulders tussen',
      selectGym: 'Selecteer een hal voor notificaties',
      gymsSelected: '{number} geselecteerd',
      noGuarantee:
        'Noot: de admin van de hal beslist welke updates worden verzonden, dus er is geen garantie dat je altijd een berichtje krijgt.',
    },
  },
  gyms: {
    title: 'Beschikbare hallen | TopLogger',
    description:
      'Een lijst met alle hallen die beschikbaar zijn in TopLogger. Kies jou hal om je routes en boulders te loggen.',
    gyms: 'Hallen',
    visitedGyms: 'Bezochte hallen',
    visitedGymsSubhead: 'Waar je afgelopen zes maanden wat gelogd hebt',
    gymsNearby: 'Hallen in de buurt',
    selectGym: 'Selecteer je huidige hal',
    goToGym: 'Naar hal',
    gymLogo: 'Hal logo',
    commingSoon: 'Komt binnenkort...',
    noSwitching: 'Om van hal te wisselen moet je de app zelf downloaden.',
    notAllowed: 'Sorry! Niet toegestaan...',
  },
  climbs: {
    title: 'Huidige {routesBoulders} in {gymName} | TopLogger',
    descriptionMap: 'Een interactieve map met alle {routesBoulders} die momenteel beschikbaar zijn bij {gymName}.',
    descriptionList: 'Een lijst met alle {routesBoulders} die momenteel beschikbaar zijn bij {gymName}.',
    listView: 'Lijstweergave',
    mapView: 'Mapweergave',
    showList: 'Lijst weergeven',
    viewMap: 'Bekijk kaart',
    new: 'Nieuw',
    guestMsg: 'Dit is een gast-account',
    groups: 'Groepen',
    walls: 'Wanden',
    filters: {
      filters: 'Filters',
      filterBtn: 'Filter routes',
      activeMsg: 'Filters zijn actief.',
      grade: 'Graad',
      starRating: 'Sterren waardering',
      colors: 'Kleur',
      todo: 'Todo',
      almostRemoved: 'Gaat er bijna uit',
      forChildren: 'Geschikt voor kinderen',
      selectAll: 'Selecteer alles',
      selectAllFiltered: 'Selecteer alle gefilterde',
      selectAllInView: 'Selecteer alles in beeld',
      deselectAll: 'Deselecteer alles',
      clearFilters: 'Filters wissen',
    },
    group: {
      sharedWith: 'Gedeelde groep met:',
      hiddenWall: '1 wand verborgen door filters',
      hiddenWalls: '{count} wanden verborgen door filters',
      hiddenGroup: '1 group verborgen door filters',
      hiddenGroups: '{count} groepen verborgen door filters',
      hiddenRoute: '1 route verborgen door filters',
      hiddenRoutes: '{count} routes verborgen door filters',
      hiddenboulder: '1 boulder verborgen door filters',
      hiddenBoulders: '{count} boulders verborgen door filters',
    },
    log: {
      done: 'Gedaan',
      top: 'Top',
      flash: 'Flash',
      onsight: 'On-sight',
      rp: 'RP',
      fl: 'FL',
      os: 'OS',
      repeat: 'Herhaling',
      check: 'Vinkje',
      tickOff: 'Afvinken',
      zones: 'Zones',
      zone: 'Zone',
      myOpinion: 'Mijn graad',
      tries: 'Pogingen',
      repeats: 'Herhalingen',
      uncheckMsg: 'Hiermee verwijder je log van {timeAgo}.',
      removeAscend: 'Verwijder log',
      project: 'Project',
      getApp: {
        toastTitle: 'Niet hier',
        toastMsg: 'Ga naar toplogger.nu om het op je eigen device te installeren...',
        header: 'Deze app is vergrendeld',
        title: 'Installeer het op je eigen device',
        explanation:
          "Als je je progressie op wilt slaan, ga dan naar <span class='tl-text-primary'>toplogger.nu</span> en download de <i>gratis</i> app of gebruik de web-versie.",
      },
      all: {
        areYouSure: 'Weet je het zeker?',
        update: 'Hiermee wijzig je logs van {timeAgo}.',
        uncheck: 'Hiermee verwijder je een log per {routeBoulder}, inclusief beklimmingen van {timeAgo}.',
        updateAscends: 'Update logs',
        removeAscends: 'Verwijder logs',
        logRoutes: 'Log alle {count} routes',
        logBoulders: 'Log alle {count} boulders',
      },
      stats: {
        stats: 'Stats',
        statistics: 'Statistieken',
        dateSet: 'Schroefdatum',
        dateRemoved: 'Uitschroefdatum',
        votesTitle: 'Stemmenoverzicht',
        votesEmpty: 'Er zijn nog geen stemmen voor de graad...',
        votesTotal: '{votes} totaal',
        ratingTitle: 'Reviewoverzicht',
        ratingEmpty: 'Er zijn nog geen beoordelingen...',
        toppedHeader: 'Getopt door',
        toppedSubhead: 'Exclusief anonieme gebruikers',
        notToppedBoulder: 'Deze boulder is nog niet getopt.',
        notToppedRoute: 'Deze route is nog niet getopt.',
      },
      renew: {
        renewedSoonBoulder: 'Binnenkort vernieuwd',
        renewedSoonRoute: 'Binnenkort vernieuwd',
        vote: 'Stem voor vernieuwing',
        unvote: 'Stem voor vernieuwing intrekken',
        voted: 'Gestemd (anoniem)',
        votedMsg: 'Je hebt gestemd om {thisRouteBoulder} te vernieuwen.',
        unvoted: 'Stem verwijderd',
        unvotedMsg: 'Je stem om {thisRouteBoulder} te vernieuwen is verwijderd.',
      },
      report: {
        report: 'Melden',
        reportClimb: 'Meld {thisRouteBoulder}',
        explanation: 'Er is iets mis, waarschuw de hal.',
        messageLabel: 'Bericht aan de hal',
        successMsg: 'Je melding wordt naar de hal gestuurd.',
      },
      help: {
        header: 'Loggen uitgelegd',
        buttons: {
          header: 'Log knoppen',
          subhead: 'Welke knop is wanneer van toepassing?',
          topExpl: 'Je hebt hem geklommen.',
          flashExpl: 'Je hebt hem in je eerste poging geklommen.',
          os: 'OS: On-sight',
          osExpl: 'Geklommen<sup>1</sup> in je eerste poging, zonder beta<sup>2</sup>.',
          fl: 'FL: Flash',
          flExpl: 'Geklommen<sup>1</sup> in je eerste poging, maar met beta<sup>2</sup>.',
          rp: 'RP: RedPoint',
          rpExpl: 'Geklommen<sup>1</sup>, maar niet in je eerste poging.',
          elaboration:
            'RedPointen bestaat vaak uit het uitwerken van alle bewegingen en langere tijd herhalen van de route tot het lukt.',
          sup1: '<sup>1</sup>Geklommen: Boven komen zonder vallen of enige touwsteun.',
          sup2:
            '<sup>2</sup>Beta: Enige kennis over de route van anderen, doordat ze het je hebben verteld of omdat je ze het hebt zien klimmen.',
        },
        stability: {
          header: 'Stabiliteitsindicator',
          explanation:
            'De gekleurde stip onder de graad is een aanduiding voor de stabiliteit van de graad. Deze kan drie waarden aannemen:',
          unstableText:
            '<strong>Instabiel</strong>: Er zijn nog niet genoeg stemmen. Deze graad kan nog worden aangepast.',
          midStableText:
            '<strong>Waarschijnlijk stabiel</strong>: Er zijn nog wat extra stemmen vereist, maar de graad stabiliseert zich.',
          stableText:
            '<strong>Stabiel</strong>: Er hebben veel gebruikers op deze graad gestemd. Kleine kans dat deze nog zal wijzigen.',
        },
        questions: {
          QgradeChange: 'Wat gebeurt er met mijn score als de graad verandert?',
          AgradeChange: 'Geen zorgen: die wordt per direct opnieuw berekend.',
        },
      },
    },
  },
  dashboard: {
    title: 'TopLogger dashboard voor {gymName}',
    description: 'Ranglijsten van de hal, nieuwe routes en je persoonlijke statistieken en voortgang bij {gymName}.',
    otherUserMsg: 'Dit zijn de statistieken van {userName}',
    viewClimbs: 'Bekijk {routesBoulders}',
    showMine: 'Mijn stats',
    showDemoGraph: 'Demo-grafiek weergeven',
    showingDemoGraph: 'Demo-grafiek wordt weergegeven',
    notEnoughLogged: 'Je hebt niet lang genoeg gelogd om een goede grafiek te maken.',
    guestUser: 'Gast gebruiker',
    statsLoadErrorMsg: 'Kon gebruikers statistieken niet laden.',
    whatsapp: 'WhatsApp',
    facebook: 'Facebook',
    twitter: 'Twitter',
    link: 'Link',
    score: {
      subhead: 'Stats en voortgang',
      countHeader: 'Geklommen {routesBoulders}',
      countHeaderExpl: 'Aantal getopte {routesBoulders} bij {gymName}',
      gradeHeader: '{routeBoulder} graad',
      gradeHeaderExpl: 'Gemiddelde van je top 10 beklimmingen bij {gymName} afgelopen twee maanden',
      historyHeader: '{routeBoulder} graad geschiedenis',
      historySubhead: 'Getallen gebaseerd op tops in <strong>alle</strong> hallen.',
      countBarText: '{count} van {total}',
      gradeBarTooltip: 'Nog {percentLeft}% voor het bereiken van de graad ',
      effective: 'Effectief',
      bonus: 'Bonus',
      points: 'Punten',
      average: 'Gemiddeld',
      bonusExplHeader: 'Over bonus punten',
      bonusExplOS: '<b>On-sight</b> geeft twee extra plusjes, equivalent aan 33.33 punten.',
      bonusExplFL: '<b>Flash</b> geeft een extra plusje, equivalent aan 16.66 punten.',
    },
    ranking: {
      header: 'Ranglijsten voor {gymName}',
      noName: 'Geen naam',
      setNameMsg:
        'Stel je naam in om jezelf in de ranglijsten te plaatsen. Je kunt daar nog steeds voor anoniem kiezen.',
      anonymousMsg: 'Je bent anoniem, dus je staat niet in de ranglijsten.',
      countHeader: 'Tops ranglijst',
      countSubhead: 'Meeste tops bij {gymName}',
      gradeHeader: 'Graad ranglijst',
      gradeSubhead: 'Gebaseerd op gemiddelde van top 10 afgelopen twee maanden',
      all: 'Alle',
      tooFewRoutesMsg0: 'Je hebt nog geen routes geklommen.',
      tooFewRoutesMsg1: 'Je hebt slechts een route geklommen.',
      tooFewRoutesMsg: 'Je hebt slechts {count} routes geklomen.',
      tooFewBouldersMsg0: 'Je hebt nog geen boulders geklommen.',
      tooFewBouldersMsg1: 'Je hebt slechts een boulder geklommen.',
      tooFewBouldersMsg: 'Je hebt slechts {count} boulders geklomen.',
      logMoreMsg: 'Log nog {count} beklimmingen voor een fatsoenlijk top 10 gemiddelde.',
      logRoutesBtn: 'Log routes',
      logBouldersBtn: 'Log boulders',
      tooFewLogsMsg: 'Gebaseerd op {count} logs',
    },
    oldNew: {
      header: '{routeBoulder} updates',
      subhead: 'Wat updates over {routesBoulders} in je hal',
      lastIn: 'Laatst geschroefd',
      newClimbs: 'Nieuwe {routesBoulders}',
      firstOut: 'Eerste eruit',
      oldClimbs: 'Binnenkort verwijderde {routesBoulders}',
      daysLeft: 'Nog {count} dagen',
      nothing: 'Niets...',
    },
  },
  competitions: {
    title: 'Wedstrijden bij {gymName} | TopLogger',
    description:
      'Een lijst van alle competities bij {gymName}. Huidige competities en wedstrijden die al voorbij zijn, met hun ranglijsten.',
    compTitle: '{competitionName} bij {gymName} | TopLogger',
    compDescription: "Resultaten van de wedstrijd '{competitionName}' bij {gymName}.",
    compClimbsTitle: "De {routesBoulders} voor '{competitionName}' | TopLogger",
    compClimbsDescMap: 'Een interactieve map met alle {routesBoulders} voor {competitionName}.',
    compClimbsDescList: 'Een lijst met alle {routesBoulders} voor {competitionName}.',
    noCompetitionsMsg: 'Er zijn momenteel geen wedstrijden bij {gymName}...',
    notParticipatingInMsg: 'Wedstrijden waar je momenteel nog niet aan deelneemt:',
    competitionResults: 'Wedstrijdresultaten',
    competition: 'Wedstrijd',
    expiredComp: 'Verlopen wedstrijd',
    expiredComps: 'Verlopen wedstrijden',
    expiredCompsSubhead: 'Bekijk de resultaten',
    noMoreLogging: 'Je kunt niet meer loggen voor deze wedstrijd.',
    logSubhead: 'Toont tops sinds het begin van {compName} {startDate}',
    expiredToastr: 'Verlopen wedstrijd',
    expiredToastrMsgJoin: 'De deadline is al verstreken, je kunt nu niet meer deelnemen',
    expiredToastrMsgQuit: 'De deadline is al verstreken, je kunt nu niet meer stoppen',
    participate: 'Deelnemen',
    participated: 'Je hebt deelgenomen',
    participating: 'Je doet mee',
    subscribed: 'Je hebt je ingeschreven',
    logClimbs: 'Log beklimmingen',
    notStarted: 'Deze wedstrijd is nog niet begonnen',
    unranked: 'Unranked',
    approvingMsg: 'In afwachting van goedkeuring door de hal.',
    approvingMsg2: 'Maar je kunt al wel beginnen met loggen.',
    approvingToastr: 'In afwachting van goedkeuring',
    approvingToastrMsg: 'Je aanvraag moet worden goedgekeurd door de hal.',
    approvedToastrMsg: 'Nou hup klimmen!',
    genderRequiredMsg: 'Voor deelname is een geslacht vereist',
    ageRequiredMsg: 'Voor deelname is je leeftijd vereist',
    setGender: 'Selecteer geslacht',
    setAge: 'Leeftijd instellen',
    unsubscribeTitle: 'Uitschrijven bij {competitionName}',
    unsubscribeMsg: 'Weet je zeker dat je wilt uitschrijven?',
    unsubscribe: 'Uitschrijven',
    unsubscribedMsg: "Je neemt niet langer deel aan '{competitionName}'.",
    start: 'Start',
    deadline: 'Deadline',
    ranking: 'Ranglijst',
    toggleRanking: 'Ranglijst',
    delayedScoresMsg: 'Scores voor deze wedstrijd worden elke 30 seconden herberekend.',
    refresh: 'Scores vernieuwen',
    switchPoule: 'Wissel poule',
    selectPoule: 'Selecteer poule',
    selectPouleExpl:
      'Deze wedstrijd werkt met gegroepeerde ranglijsten. Selecteer de poule waaraan u wilt deelnemen. Dit kan later worden gewijzigd.',
    help: {
      btn: 'Help',
      header: 'Over competities',
      generalTitle: 'Hoe het werkt',
      generalExpl:
        'Een competitie is een ranglijst gebaseerd op alleen die tops die gelogd zijn in het stijdsbestek tussen de start en de deadline.',
      generalExpl2:
        'Als je een route al hebt geklommen voor de start van de competitie, dan moet je deze herhalen om hem toch voor je score te laten tellen. Het aantal punten per beklimming staat boven elke competitie.',
      poulesTitle: 'Gegroepeerde ranglijsten',
      poulesExpl:
        "Wedstrijden kunnen gegroepeerde ranglijsten of 'Poules' hebben. Dit betekend dat je kunt deelnemen in een sub-competitie die alleen bestaat uit klimmers met ongeveer het zelfde klimniveau. Bij inschrijven krijg je een popup te zien waarin je je eigen (redpoint) graad kunt invoeren, dus eerlijkheid wordt gewaardeerd.",
    },
    info: {
      header: 'Regels en voorschriften',
      subhead: 'Specifiek voor {groupName}',
      rules: 'Regels',
      pricesHeader: 'Te winnen prijzen',
      scoreSystem: 'Scoresysteem',
      points: {
        description:
          'Ranglijst gebaseerd op punten. Elke top geeft punten, mogelijk meer wanneer deze in een keer wordt geklommen.',
        points: '{points} punten',
        bonus: '{points} bonuspunten',
      },
      pointsByGrade: {
        description:
          'Ranglijst gebaseerd op punten. Elke top geeft punten, mogelijk meer wanneer deze in een keer wordt geklommen. Het aantal punten per route is gelinkt aan de graad van de route, volgens de graad-conversietabel. Als er zones zijn telt elke zone als een Top.',
        button: 'Graad conversie tabel',
        explanation: 'Punten naar rato de graad.',
        efforts:
          'Allen je beste {efforts} resultaten worden gebruikt om je score te berekenen. Als er zones zijn telt iedere zone als een resultaat.',
      },
      divBy: {
        description:
          'Elke top (en elke zone) geeft punten, en wel 1000 gedeeld door het aantal deelnemers dat hem geklommen heeft.',
        bonusPercent: '{percentage}% bonuspunten',
      },
    },
    setGrade: {
      header: 'Stel je deelnamegraad in',
      explanation:
        'Deze wedstrijd werkt met poules, waarin je wordt ingedeeld aan de hand van je klimniveau. Binnen je poule strijd je tegen klimmers met een vergelijkbare graad.',
      explanation2:
        'Stel je huidige maximale (redpoint) graad in. Deze graad zal worden gebruikt om je poule te bepalen:',
      minGradeExplanation: 'Je hebt al laten zien dat dit je minimale graad is: ',
    },
  },
  gym: {
    title: '{gymName} - Generieke informatie | TopLogger',
    description: 'Contact gegevens, openingstijden en een map voor {gymName}.',
    currentGym: 'Huidige hal:',
    contact: 'Contact',
    phone: 'Telefoon',
    parking: 'Parkeren',
    website: 'Website',
    gallery: 'Gallerij',
    gallerySubhead: 'Wat impressies van de gym',
    impression: 'Impressie',
    hours: 'Openingstijden',
    closed: 'Gesloten',
    Monday: 'Maandag',
    Tuesday: 'Dinsdag',
    Wednesday: 'Woensdag',
    Thursday: 'Donderdag',
    Friday: 'Vrijdag',
    Saturday: 'Zaterdag',
    Sunday: 'Zondag',
    location: 'Locatie',
  },
  reservations: {
    title: 'Reserveringen voor {gymName} | TopLogger ',
    description: 'Zoek beschikbare tijdsleuven en maak een reservering om {gymName} te bezoeken.',
    accountRequiredMsg: 'Je hebt een account nodig om een tijdvak te kunnen reserveren',
    slotsHeader: 'Beschikbare tijdvakken',
    selectArea: 'Selecteer je gebied',
    spotsLeft: '{spots} plekken over',
    dialogTitle: 'Reserveer je tijdslot',
    notAvailableYet: 'Nog niet beschikbaar',
    available: 'Beschikbaar',
    customerInformation: 'Klant informatie',
    associate: 'Partner',
    addAssociate: 'Partner toevoegen',
    removeAssociate: 'Partner verwijderen',
    book: 'Reserveren',
    myBookings: 'Mijn reserveringen',
    expiredBookings: 'Verlopen reserveringen',
    lastMinuteBooking: 'Dit is een last-minute boeking',
    lastMinuteBookingExpl: 'Last-minute boekingen tellen niet mee voor restricties in het aantal boekingen',
    fullyBooked: 'Volgeboekt',
    cancelBooking: 'Boeking annuleren',
    bookingNumber: 'Boekingsnummer',
    clientNumber: 'Klantnummer',
    passwordHint: 'Het wachtwoord specifiek voor dit tijdvak',
    sendConfirmation: 'Stuur een bevestigingsmail',
    acceptContact: 'Ik sta toe dat de hal contact met mij opneemt in geval van wijzigingen in mijn reservering',
    checkinTime: 'Inchecktijd',
    checkout: 'Uitchecken',
    checkOutTablet:
      'Voer het e-mailadres in dat is gebruikt voor je reservering om uit te checken. Eventuele partners van je boeking zullen ook worden uitgecheckt.',
    emails: {
      confirmation: {
        subject: 'Boeking bij {gym_name} bevestigd',
        confirmed: 'Boeking bevestigd',
        activity_at: '{activity} bij {gym_name}',
        confirmation: 'Je boeking voor {area_name} bij {gym_name} is bevestigd.',
        checkin_time: 'Inchecktijd',
        booking_number: 'Boekingsnummer',
        manage_online: 'Je kunt je boekingen online beheren via TopLogger:',
        manage_booking: 'Beheer boeking',
        thanks: 'Bedankt!',
      },
      no_show: {
        subject: 'Gemiste boeking',
        you_are_marked: 'Je boeking bij {gym_name} is gemarkeerd als een no-show.',
        time_was: 'De inchecktijd was op {date} tussen {time_start} en {time_end}.',
        cancel: 'Je kunt je reserveringen op elk moment annuleren door in te loggen op je TopLogger-account.',
        questions: 'Neem bij vragen rechtstreeks contact op met de hal.',
      },
      checkoutReminder: {
        subject: 'Herinnering om uit te checken',
        reminder: 'Dit is een vriendelijke herinnering van {gym_name} om aan de balie uit te checken.',
        time_is: 'Je uitchecktijd is op {date} om {time_end}.',
      },
    },
  },
  help: {
    title: 'Help | TopLogger',
    description: 'Hoe het werkt. De regels voor klimmen, toppen en je beklimmingen loggen in TopLogger.',
    faq: {
      header: 'FAQ',
      subhead: 'Veelgestelde vragen',
      example: 'Voorbeeld',
      qGradeCalc: 'Hoe wordt mijn graad berekend?',
      aGradeCalc:
        'Je graad wordt berekend aan de hand van je top tien gemiddelde beklimmingen in de afgelopen twee maanden.',
      aGradeCalcEx1:
        "Je tien beste beklimmingen bevatten vijf 6a's en vijf 6a⁺en. Dit betekend dat je graad precies halverwege tussen 6a en 6a⁺ zit. Je bent dus 50% op weg om 6a⁺-klimmer te worden, dus je huidige graad is nu '6a en 50%'.",
      aGradeCalcEx2:
        "Je tien beste beklimmingen bevatten een 6a en negen 6a⁺en. Dit betekend dat je graad bijna 6a⁺ is: je moet er nog maar een. Je bent nu 90% op weg om 6a⁺-klimmer te worden, dus je huidige graad is nu '6a with 90%'.",
      showPoints: 'Laat het puntensysteem zien',
      qTopFlash: 'Wat is het verschil tussen Top en Flash?',
      qTopFlashRp: 'Wat is RP, FL en OS?',
      qCareTopFl: 'Waarom zou Top of Flash mij uitmaken?',
      aCareTopFl:
        'Flash geeft meer punten: Een boulder flashen telt voor de berekening van je graad alsof de boulder een plusje moeilijker was.',
      aCareTopFlEx1:
        'Je hebt een 6a geflashed. In de berekening van je graad telt deze alsof je een 6a⁺ hebt geklommen.',
      aCareTopFlEx2:
        'Je hebt een 6c⁺ geflashed. In de berekening van je graad telt deze alsof je een 7a hebt geklommen.',
      qCareTopFlRp: 'Waarom zou Redpoint, Flash of OnSight mij uitmaken?',
      aCareTopFlRp:
        'Flash en OnSight geeft je meer punten. Een route flashen telt voor de berekening van je graad alsof deze een plusje moeilijker was. Een route On-sighten voegt zelfs twee plusjes toe.',
      aCareTopFlRpEx1:
        'Je hebt een 6a geflashed. In de berekening van je graad telt deze alsof je een 6a⁺ hebt geklommen.',
      aCareTopFlRpEx2:
        'Je hebt een 6a ge-on-sight. In de berekening van je graad telt deze alsof je een 6b hebt geklommen.',
      aCareTopFlRpEx3:
        'Je hebt een 6c⁺ geflashed. In de berekening van je graad telt deze alsof je een 7a hebt geklommen.',
      aCareTopFlRpEx4:
        'Je hebt een 6c⁺ ge-on-sight. In de berekening van je graad telt deze alsof je een 7a⁺ hebt geklommen..',
      qScoreTime: 'Hoe lang tellen mijn logs in mijn ranking?',
      aScoreTimeGrade:
        'Je top 10 beklimmingen in de afgelopen twee maanden tellen in je ranking. Ongeacht of de route van een van die logs al is verwijderd.',
      aScoreTimeCount:
        'Alle logs van routes die momenteel in je hal zijn tellen mee in je ranking. Als een route wordt verwijderd zakt iedereen die deze heeft getopped met een in de ranking.',
      qGradeStability: 'Wat is die gekleurde stip?',
    },
    guide: {
      header: 'Beginners gids voor klimmen',
      subhead: 'Dit zijn de spelregels',
      intro:
        'Bouldering gaat om boven komen, gebruikmakend van een enkele kleur. Maar er zijn wat spelregels. Hier volgt een korte uitleg:',
      starting: 'Beginnen',
      startingExpl1:
        'Begin met je handen op de grepen met de streepjes. Plaats je voeten in de wand en hang stabiel voor je begint met klimmen. Dus niet springen tenzei dat expliciet staat omschreven in de omschrijving van de boulder.',
      startingExpl2:
        'Er kunnen meer voorwaarden zijn, zoals zitstart, wat betekend dat je moet beginnen vanuit een positie zittend op de mat.',
      climbing: 'Klimmen',
      climbingExpl: "Klimmen is 'simpel': je mag maar een kleur gebruiken.",
      topping: 'Toppen',
      toppingExpl:
        "Je mag zeggen dat je een boulder hebt 'getopped' als je de eindgreep met beide handen drie seconden achter elkaar vast hebt gehouden.",
      logging: 'Loggen',
      loggingExpl: 'Loggen kan op drie manieren:',
      loggingOs: 'On-Sight (OS) <small>eerste poging, geen info</small>',
      loggingOsExpl:
        "Als je boven bent gekomen in je eerste poging zonder informatie van anderen, dan heet dit 'On-Sight'",
      loggingFl: 'Flash (FL) <small>eerste poging, met info</small>',
      loggingFlExpl:
        "Als je boven bent gekomen in je eerste poging, maar informatie had over hoe het moest (of niet moest), dan heet dit 'flash'. Dit is bijvoorbeeld het geval wanneer je iemand de boulder eerder hebt zien proberen, of iemand heeft je verteld hoe het moest.",
      loggingRp: 'Red-Point (RP) <small>de rest</small>',
      loggingRpExpl: "Als je de boulder hebt getopped, maar niet in je eerste poging, dan heet dit 'Red-Point'.",
    },
  },
  contact: {
    title: 'Contact | TopLogger',
    description: 'Neem contact op en maak TopLogger beschikbaar in jou gym.',
    header: 'Contact',
    subhead: 'Wat kunnen we voor elkaar betekenen',
    wantInGym: 'Wil je TopLogger beschikbaar in je eigen hal?',
    sendMail: 'Stuur ons gewoon een mailtje:',
  },
  feedback: {
    title: 'Feedback | TopLogger',
    description: 'Geef je mening over TopLogger.',
    header: 'Geef feedback',
    subhead: 'Verbeteringen voorstellen',
    forGym: 'Feedback voor {gymName}',
    forApp: 'Feedback voor de app',
    explanation: 'Is er iets mis met de app of heb je een idee ter verbetering?',
    explanationGym:
      'Vertel {gymName} wat ze kunnen verbeteren, wat er stuk is, of gebruik het simpelweg als ideeënbus.',
    gymFeedbackMsg: 'Voor missende routes of verkeerde data kun je beter met je hal contact opnemen.',
    message: 'Bericht',
    feedbackGym: 'Gym Feedback',
    feedbackApp: 'App Feedback',
    yourFeedback: 'Jou gegeven feedback',
    yourFeedbackSub: 'Ontvang reacties',
    reply: 'Reactie',
    retract: 'Intrekken',
    inputLabel: 'Schrijf je feedback (anoniem)',
    successMsg: 'Geweldig! Bedankt voor je feedback!',
  },
  terms: {
    title: 'Servicevoorwaarden | TopLogger',
    description: 'Servicevoorwaarden en privacybeleid voor TopLogger',
  },
  components: {
    gradesChart: {
      header: 'Graad conversietabel',
      explanation: 'Punten relatief ten opzichte van de verschillende gradatiesystemen',
    },
  },
  $vuetify, // Do not translate me please :)
}
